<template>
  <section class="d-flex flex-wrap justify-center align-center justify-items-center" id="bg-overlay">
    <!-- <div class="container my-auto pb-12"> -->
      <div id="login-modal">
        <!-- <v-card id="login-card"> -->
        <!-- <v-card id="header" tile elevation="0" color="transparent" class="text-center pa-8 px-sm-12"> -->
        <div class="text-center">
          <v-icon v-if="!$route.meta.logo" color="secondary" size="96">mdi-shield-key-outline</v-icon>
          <v-img v-else width="120" class="mx-auto" :src="logo" />
          <h1 class="my-2 pa-0 text-uppercase text-center">UNIOM<br /><span>Beléptetőrendszer</span></h1>
          <small text-center>{{ $route.meta.subtitle }}</small>
        </div>
        <!-- </v-card> -->

        <!-- <v-card id="body" tile elevation="0" color="transparent" :loading="loading"> -->
        <div class="pa-4 px-sm-12">
          <form @submit.prevent="login">
            <v-text-field label="Email" type="email" filled dense v-model="form.email" :error-messages="errors.email"
              autofocus />
            <v-text-field label="Jelszó" filled v-model="form.password" :error-messages="errors.password"
              :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword" />

            <v-expand-transition>
              <v-alert v-if="errors.login && errors.login.length" dense filled dismissible border="left" type="error">
                <template v-for="error in errors.login">
                  {{ error }}
                </template>
              </v-alert>
              <v-alert v-if="messageText" dense text :type="messageType">
                <div v-html="messageText"></div>
              </v-alert>
            </v-expand-transition>

            <div class="d-flex flex-column justify-center align-center">
              <v-btn type="submit" color="accent" x-large depressed :loading="loading" :disabled="loading">
                <v-icon class="mr-2" small>mdi-login-variant</v-icon>
                Bejelentkezés
              </v-btn>
            </div>
          </form>
        </div>
        <!-- </v-card> -->
        <!-- </v-card> -->
        <div class="text-center" style="position: relative">
          <small class="text-caption" :title="`Build #${buildNumber}\n${buildDate}`">
            {{ version }}
          </small>
        </div>
      </div>
      <!-- <div class="" style="width: max(0px, calc(40vw - 200px));"></div> -->
    <!-- </div> -->
  </section>
</template>

<script>
import { version } from '@/../package.json';

export default {
  data: function () {
    return {
      version,
      form: {
        email: '',
        password: '',
      },
      message: {
        text: null,
        type: null,
      },
      loading: false,
      showPassword: false,
      errors: [],
    };
  },

  mounted() {
    this.$store.commit('removeToken');
    this.$store.commit('removeUser');
  },

  methods: {
    async login() {
      this.loading = true;
      this.errors = {};
      try {
        const response = await this.$http.post('auth/login', this.form);
        if (Object.keys(response.errors).length) {
          throw response.errors;
        }

        this.message.text = 'Sikeres bejelentkezés';
        this.message.type = 'success';

        this.$store.commit('setUser', response.user);
        this.$router.push(this.$route.params.redirect || '/');
      } catch (err) {
        setTimeout(() => (this.errors = err), 500);
      } finally {
        setTimeout(() => (this.loading = false), 1000);
      }
    },
  },
  computed: {
    buildDate() {
      return new Date(process.env.VUE_APP_BUILD_DATETIME).toLocaleString();
    },
    buildNumber() {
      return parseInt(process.env.VUE_APP_BUILD_NUMBER);
    },
    logo() {
      console.log(this.$route.meta)
      return require('@/' + this.$route.meta.logo);
    },
    messageText() {
      return this.$route.params.message
        || this.message.text
        || false;
    },
    messageType() {
      return this.$route.params.messageType
        || this.message.type
        || 'warning';
    }
  },
};
</script>

<style lang="scss" scoped>
section {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  position: absolute;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  max-width: 480px !important;
  min-width: 340px;
  margin: auto;

  #bg-overlay {
    #login-modal {
      margin: auto;

      #login-card {
        backdrop-filter: blur(2rem) saturate(0.75);
      }

      #header {
        border-bottom: 2px solid var(--v-primary-base) !important;

        #logo {
          font-size: 63px;
        }

        h1 {
          line-height: 1 !important;
          font-size: 36px !important;
          letter-spacing: -4px;
          font-weight: 600;

          &>span {
            font-size: 28px;
            letter-spacing: -3px;
            font-weight: 300;
          }
        }
      }

      #body {
        h2 {
          color: inherit;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
