<template>
  <section class="d-flex flex-wrap justify-center align-center justify-items-center" id="bg-overlay">
    <div id="login-modal" class="white--text">
      <div class="text-center mb-6">
        <v-icon v-if="!$route.meta.logo" color="secondary" size="96">mdi-shield-key-outline</v-icon>
        <img v-else height="80" width="280" contain class="mx-auto mb-4" :src="logo" />
        <h1 class="my-2 mt-6 pa-0 text-uppercase text-center">Beléptetőrendszer</h1>
        <small text-center>{{ $route.meta.subtitle }}</small>
      </div>
      <div class="pa-4 px-sm-12">
          <div v-if="shibLogin" class="d-flex flex-column justify-center align-center">
            <v-btn color="accent" block depressed x-large @click="loginWithShibboleth" :loading="loading">
              <v-icon class="mr-2" small>mdi-login-variant</v-icon>
              Bejelentkezés címtárral
            </v-btn>

            <v-btn @click="shibLogin = false" color="grey lighten-5" class="mt-6" text small depressed :loading="loading" :disabled="loading">
              Címtár nélküli bejelentkezés
            </v-btn>
          </div>
          <form v-else @submit.prevent="login">
            <v-text-field label="Email" type="email" filled dense v-model="form.email" dark
              :error-messages="errors.email" autofocus />
            <v-text-field label="Jelszó" filled v-model="form.password" :error-messages="errors.password" dark
              :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword" />

            <v-expand-transition>
              <v-alert v-if="errors.login && errors.login.length" dense filled dismissible border="left"
                type="error">
                <template v-for="error in errors.login">
                  {{ error }}
                </template>
              </v-alert>
              <v-alert v-if="$route.params.message" dense text type="warning">
                {{ $route.params.message }}
              </v-alert>
            </v-expand-transition>

            <div class="d-flex flex-column justify-center align-center">
              <v-btn type="submit" color="accent" x-large depressed :loading="loading" :disabled="loading">
                <v-icon class="mr-2" small>mdi-login-variant</v-icon>
                Bejelentkezés
              </v-btn>

              <v-btn color="grey lighten-5" class="mt-6" text small @click="shibLogin = true">
                Címtáras bejelentkezés
              </v-btn>
            </div>
          </form>
      </div>
      <div class="text-center" style="position: relative">
        <small class="text-caption" :title="`Build #${buildNumber}\n${buildDate}`">
          {{ version }}
        </small>
      </div>
    </div>
  </section>
</template>

<script>
import { version } from '@/../package.json';
// import { client } from '@passwordless-id/webauthn'

export default {
  data: function () {
    return {
      shibLogin: true,
      shibWindow: null,
      shibTimer: null,
      loadingTimer: null,
      version,
      form: {
        email: '',
        password: '',
      },
      message: {
        text: null,
        type: null,
      },
      loading: false,
      showPassword: false,
      errors: [],
    };
  },

  async mounted() {
    this.$store.commit('removeToken');
    this.$store.commit('removeUser');

    var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message';

    // Listen to message from child window
    eventer(
      messageEvent,
      (e) => {
        // Check if origin is proper
        // if (e.origin != 'http://localhost:8020') {
        //   return;
        // }

        if (e.data.user) {
          if (this.shibWindow) {
            this.shibWindow.close();
            this.shibWindow = null;
          }
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.$store.commit('setToken', e.data.token);
          this.$store.commit('setUser', JSON.parse(e.data.user));
          this.$router.push(this.$route.params.redirect || '/');
        }
      },
      false
    );
  },

  methods: {
    async loginWithShibboleth() {
      this.loading = true;
      clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        this.loading = false;
      }, 5000);
      this.shibWindow = window.open(
        this.shibLoginUrl,
        'Bejelentkezés',
        'status=1, toolbar=0, width=800, height=720'
      );
      this.shibTimer = setInterval(() => {
        if (this.shibWindow?.closed) {
          clearInterval(this.shibTimer);
          this.loading = false;
        }
      }, 500);
    },
    async login() {
      this.loading = true;
      clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        this.loading = false;
      }, 5000);
      this.errors = {};
      try {
        const response = await this.$http.post('auth/login', this.form);
        if (Object.keys(response.errors).length) {
          throw response.errors;
        }
        this.$store.commit('setUser', response.user);
        this.$router.push(this.$route.params.redirect || '/');
      } catch (err) {
        setTimeout(() => (this.errors = err), 500);
      } finally {
        setTimeout(() => (this.loading = false), 1000);
      }
    },
  },
  computed: {
    buildDate() {
      return new Date(process.env.VUE_APP_BUILD_DATETIME).toLocaleString();
    },
    buildNumber() {
      return parseInt(process.env.VUE_APP_BUILD_NUMBER);
    },
    shibLoginUrl() {
      return `${process.env.VUE_APP_API_URL}auth/shib/redirect?target=${process.env.VUE_APP_API_URL}auth/shib/login`;
    },
    logo() {
      console.log(this.$route.meta)
      return require('@/' + this.$route.meta.logo);
    },
    messageText() {
      return this.$route.params.message
        || this.message.text
        || false;
    },
    messageType() {
      return this.$route.params.messageType
        || this.message.type
        || 'warning';
    }
  },
};
</script>

<style lang="scss" scoped>
#bg-overlay {
  // background-color: var(--v-secondary-darken1);
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  padding: 1rem;

  &::before
  {
    content: '';
    position: fixed;
    inset: 0;
    z-index: -2;
    background: url('../../assets/bg-original.jpg') no-repeat center center;
    background-size: cover;
  }

  &::after {
    content: '';
    position: fixed;
    inset: 0;
    // background: rgba(28, 36, 66, 0.5);
    // background: #222a;
    z-index: -1;
    backdrop-filter: blur(0px) saturate(0) contrast(0.3) brightness(0.3);
    // mix-blend-mode: multiply;
  }

  #login-modal {
    margin: auto;
    width: 100%;
    max-width: 480px;
    backdrop-filter: blur(2rem) brightness(0.9);
    padding: 3rem 1rem;
    border-radius: 0.5rem;
    // border: 1px solid #fff1;
    // box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    
    * {
      color: #fff;
    }

    #header {
      // border-bottom: 2px solid var(--v-primary-base) !important;

      #logo {
        font-size: 63px;
      }

      h1 {
        line-height: 1 !important;
        font-size: 45px !important;
        letter-spacing: -4px;
        font-weight: 600;

        &>span {
          font-size: 35px;
          letter-spacing: -3px;
          font-weight: 300;
        }
      }
    }

    #body {
      h2 {
        color: inherit;
        font-weight: 400;
      }
    }
  }
}
</style>
