<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('SCHEDULE_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewSchedule' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Ütemzések</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :md="4">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                label="Megnevezés"
                dense
                clearable
                hide-details
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card :loading="loading">
      <v-data-table
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
      >
        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="hasRight('SCHEDULE_WRITE')"
            text
            fab
            small
            dark
            color="primary"
            class="rounded-sm mx-2"
            :to="{ name: 'ScheduleEditor', params: { id: item.id } }"
            title="Szerkesztés"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hasRight('SCHEDULE_DELETE')"
            text
            fab
            small
            dark
            color="primary"
            class="rounded-sm mx-2"
            @click="deleteSchedule(item)"
            title="Törlés"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <v-divider class="mt-0"></v-divider>
      <div class="px-4">
        <pagination
          v-if="search.amount"
          :showcombo="1"
          :limit="search.limit"
          :amount="search.amount"
          v-model="search.page"
          @pageChanged="pageChanged"
        ></pagination>
      </div>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'ScheduleList',
      fetchItems: this.fetchScheduleGroups,
      dataTable: {
        options: {
          sortBy: ['name'],
        },
        headers: [
        { text: 'Megnevezés', value: 'name' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {
        name: '',
      },
      searchTimeout: null,
    };
  },
  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
      };
    },

    async deleteSchedule(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> ütemzés törlésére készül! Folytatja?`,
        title: 'Figyelem',
      });

      if (confirm) {
        try {
          await this.$http.get(`schedule-groups/delete/${item.id}`);
          this.fetchScheduleGroups();
          this.$dialog.notify.info('Az ütemzés törölve!');
        } catch (e) {
          console.error(e);
        }
      }
    },

    async fetchScheduleGroups() {
      this.loading = true;
      try {
        const data = JSON.parse(JSON.stringify(this.search));
        data.sort_by = this.options.sortBy || [''];
        data.sort_desc = this.options.sortDesc || [false];
        const response = await this.$http.post('schedule-groups/list', this.options);
        this.dataTable.items = response.schedule_groups;
        this.dataTable.itemsLength = response.schedule_groups_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
      };
    }
  }
};
</script>
