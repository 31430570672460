<template>
  <div>
    <h1 class="py-6">QR kódok jóváhagyása</h1>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        :item-class="itemClass"
        calculate-widths
      >
        <template #[`item.user`]="{ item }">
          {{ item.user.name }}<br>({{ item.user.email }})
        </template>

        <template #[`item.groups`]="{ item }">
          <v-chip-group>
            <v-chip
              small
              label
              link
              v-for="group in item.groups"
              :key="group.id"
              :color="group.color"
              :to="{ name: 'GroupEditor', params: { id: group.element_id } }"
              class="ma-1"
            >
              {{ group.name }}
            </v-chip>
          </v-chip-group>
        </template>

        <template #[`item.permissions`]="{ item, index }">
          <!-- <v-checkbox
            v-for="al in item.access_levels"
            :key="al.access_level_id"
            v-model="al.selected"
            :disabled="item.approved == 1"
            :label="al.name"
            color="orange"
          />
           -->
          <v-autocomplete
            :disabled="item.approved == 1"
            :error-messages="errors[index]?.access_level_ids"
            :hide-details="!errors[index]"
            v-model="item.access_level_ids"
            :items="item.access_levels"
            item-value="element_id"
            item-text="name"
            multiple
            filled
            small-chips
            dense
            :value-comparator="$config.comparators.isEqual"
            @input="item.access_level_ids.sort((a, b) => (a > b ? 1 : -1))"
          />
        </template>

        <template #[`item.amount`]="{ item, index }">
          <v-masked-text-field
            :disabled="item.approved == 1"
            dense
            filled
            v-model="item.amount"
            :error-messages="errors[index]?.amount"
            :hide-details="!errors[index]"
            :mask="$config.inputMasks.createNumberMask({ max: 1000 })"
          />
        </template>

        <template #[`item.approved`]="{ item }">
          <v-icon v-if="item.approved == 1" color="success">mdi-checkbox-marked</v-icon>
          <v-icon v-else color="error">mdi-close-box</v-icon>
        </template>

        <template #[`item.actions`]="{ item, index }">
          <v-btn
            fab
            small
            color="primary"
            text
            class="rounded-sm ma-1"
            v-if="item.approved == 0"
            @click="approve(item, index)"
            title="Igénylés jóváhagyása"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            color="primary"
            text
            class="rounded-sm ma-1"
            v-if="item.approved == 0"
            @click="deleteRequest(item)"
            title="Igénylés törlése"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      errors: {},
      routeName: 'QrCodeApprovalList',
      fetchItems: this.fetchQrCodeRequests,
      dataTable: {
        options: {
          sortBy: ['request_date'],
          sortDesc: [true],
        },
        headers: [
          { text: 'Igénylő', value: 'user', sortName: 'Users.name' },
          { text: 'Igénylés dátuma', value: 'request_date' },
          { text: 'Csoport', value: 'groups', sortable: false },
          { text: "Engedély", value: "permissions", sortable: false },
          { text: 'Mennyiség', value: 'amount', width: 110 },
          { text: 'Megjegyzés', value: 'comment' },
          { text: 'Jóváhagyva', value: 'approved', align: 'center' },
          { text: 'Jováhagyás dátuma', value: 'approved_date' },
          { text: '', value: 'actions', sortable: false, align: 'end', width: 120 },
        ],
      },
    };
  },

  methods: {
    async fetchQrCodeRequests() {
      this.loading = true;
      this.requestId = 0;
      try {
        const response = await this.$http.post(`qr-code-requests/approval/list`, this.postData);
        this.dataTable.items = response.requests;
        this.dataTable.itemsLength = response.requests_count;
        this.dataTable.options.page = this.$route.query.page * 1 || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async deleteRequest(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `Az igénylés törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        return;
      }

      try {
        const response = await this.$http.post(`qr-code-requests/delete/${item.id}`);
        this.fetchQrCodeRequests();
        if (response.status === 'OK') {
          this.$dialog.notify.info('Az igénylés törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },

    async approve(item, index) {
      this.errors = {};
      const confirm = await this.$dialog.confirm({
        type: 'warning',
        text: `Az igénylés jóváhagyására készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) {
        this.dataTable.highlighted = [];
        return;
      }

      try {
        const response = await this.$http.post(`qr-code-requests/approve/${item.id}`, item);
        if (!Object.keys(response.errors).length) {
          this.fetchQrCodeRequests();
          this.dataTable.highlighted = [ Number(item.id) ];
          this.$dialog.notify.info('Az igénylés jóváhagyva');
        } else {
          this.$set(this.errors, index, response.errors);
        }
      } catch (e) {
        console.error(e);
      }
    },
  }
};
</script>
