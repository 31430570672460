<template>
  <v-navigation-drawer v-model="drawer" :width="sidebarWidth" app floating :mobile-breakpoint="1024">
    <!-- <vue-scroll style="height: 100%"> -->

    <sidebar-menu :items="menu" />

    <v-sheet height="40" color="transparent"></v-sheet>
    <!-- </vue-scroll> -->

    <template v-slot:prepend>
      <router-link :to="{ name: 'Home' }">
        <v-hover>
          <v-sheet tile :width="sidebarWidth" :height="headerHeight" class="d-flex align-center" color="transparent">
          <!-- <v-sheet fab tile :width="sidebarWidth" :height="headerHeight" dark class="d-flex align-center"> -->
            <!-- <v-icon size="36" class="mx-4">mdi-shield-key-outline</v-icon> -->
            <v-img max-width="44" class="mx-4" contain :src="require('../../assets/logo.png')"></v-img>
            <div class="text-left">
              <div class="font-weight-bold">UNIOM</div>
              <div class="caption">Beléptetőrendszer</div>
            </div>
          </v-sheet>
        </v-hover>
      </router-link>
    </template>
    <template v-slot:append>
      <v-sheet flat dark :height="footerHeight" color="transparent" class="d-flex justify-center align-center pa-3">
        <v-btn large text block @click="$logout" color="green">
          <v-icon left>mdi-exit-run</v-icon>
          Exit
        </v-btn>
      </v-sheet>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { version } from '@/../package.json';
import menu from '../../menu.js';
import SidebarMenu from '@/components/SidebarMenu.vue';

export default {
  components: {
    SidebarMenu,
  },
  data: () => ({
    width: 80,
    version,
    menu,
  }),
  methods: {
    matchRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },
    matchChildRoute(name) {
      return !!this.$route.matched.filter((e) => e.path && e.name === name).length;
    },
    // hasRight(name) {
    //   if (!name) {
    //     return true;
    //   }
    //   const right = this.findRight(this.$store.state.rights, name);
    //   if (right && (right.selected || this.lodash.find(right.children, { selected: true}))) {
    //     return true;
    //   }
    //   return false;
    // },

    // findRight(rights, alias) {
    //   let result = null;

    //   for (const right of rights) {
    //     if (right.alias === alias) {
    //       result = right;
    //       break;
    //     }
    //     if (right.children) {
    //       result = this.findRight(right.children, alias);
    //       if (result) {
    //         break;
    //       }
    //     }
    //   }

    //   return result;
    // },
  },
  computed: {
    headerHeight() {
      return parseInt(process.env.VUE_APP_HEADER_HEIGHT);
    },
    footerHeight() {
      return parseInt(process.env.VUE_APP_FOOTER_HEIGHT);
    },
    sidebarWidth() {
      return parseInt(process.env.VUE_APP_SIDEBAR_WIDTH);
    },
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(value) {
        this.$store.commit('setDrawer', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item--active {
  &::before {
    display: none !important;
  }

  &>* {
    color: white !important;
  }
}

::v-deep .v-list-group__items {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    bottom: 8px;
    background-color: var(--v-primary-base);
    width: 2px;
  }

  margin-left: 24px;
}
</style>
