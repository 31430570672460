<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <import-dialog v-model="importDialog" @close="importDialog = false" @success="fetchAccessLevels" />
      <v-btn v-bind="$config.buttonAttrs.floating" title="Importálás" @click="importDialog = true">
        <v-icon dark>mdi-file-import</v-icon>
      </v-btn>

      <export-dialog v-if="exportDialog" api-endpoint="/access-levels/export" filename="Hozzáférési szintek kártyái"
        :worksheet-options="exportWorksheetOptions" worksheet-options-item-text="name"
        worksheet-options-item-value="element_id" @close="exportDialog = false" />
      <v-btn v-bind="$config.buttonAttrs.floating" title="Exportálás" @click="exportDialog = true">
        <v-icon dark>mdi-file-export</v-icon>
      </v-btn>

      <v-btn v-if="hasRight('ACCESS_LEVEL_WRITE')" v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewAccessLevel' }" title="Hozzáadás">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Hozzáférési szintek</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="3">
              <v-text-field v-model="search.name" @input="searchEventHandler" label="Megnevezés" clearable
                hide-details />
            </v-col>
            <v-col :cols="12" :sm="4" :md="3">
              <v-autocomplete label="Csoport" :items="groups" item-text="name" item-value="element_id"
                v-model="search.group_id" hide-details clearable @change="searchEventHandler"
                @click:clear="searchEventHandler" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card :loading="loading">
      <v-data-table :headers="dataTable.headers" :items="dataTable.items" :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options" :footer-props="$config.dataTableFooterProps">
        <template #[`item.groups`]="{ item }">
          <v-chip link label small v-for="(group, index) of item.groups" :key="`group-${group.element_id}-${index}`"
            :to="{ name: 'GroupEditor', params: { id: group.element_id } }" class="ma-1">
            {{ group.name }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn v-if="hasRight('ACCESS_LEVEL_WRITE')" text fab small dark color="primary" class="rounded-sm mr-1"
            :to="{ name: 'AccessLevelEditor', params: { id: item.element_id } }" title="Szerkesztés">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn v-if="hasRight('ACCESS_LEVEL_WRITE')" text fab small dark color="primary" class="rounded-sm mr-1"
            @click="deleteAccessLevel(item)" :disabled="!item.erasable" title="Törlés">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';
import ExportDialog from '@/components/ExportDialog.vue';
import ImportDialog from './ImportDialog.vue';

export default {
  mixins: [dataTablePage],
  components: { ExportDialog, ImportDialog },

  data() {
    return {
      importDialog: false,
      routeName: 'AccessLevelList',
      fetchItems: this.fetchAccessLevels,
      dataTable: {
        options: {
          sortBy: ['name'],
        },
        headers: [
          { text: 'Megnevezés', value: 'name' },
          { text: 'Csoportok', value: 'groups', sortable: false },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {
        name: '',
        group_id: 0,
      },
      groups: [],
      searchTimeout: null,
      exportDialog: false
    };
  },

  mounted() {
    this.fetchAccessLevelGroups();
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        group_id: this.$route.query.group_id,
      };
    },

    async deleteAccessLevel(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> hozzáférési szint törlésére készül! Folytatja?`,
        title: 'Figyelem',
      });

      if (confirm) {
        try {
          await this.$http.get(`access-levels/delete/${item.element_id}`);
          this.fetchAccessLevels();
        } catch (e) {
          console.error(error);
        } finally {
          this.loading = false;
        }
      }
    },

    async fetchAccessLevels() {
      this.loading = true;
      try {
        const response = await this.$http.post('access-levels/list', this.postData);
        this.dataTable.items = response.access_levels;
        this.dataTable.itemsLength = response.access_levels_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchAccessLevelGroups() {
      this.loading = true;
      try {
        const response = await this.$http.post('groups/list', { group_type: 'ACCESS_LEVEL' });
        this.groups = [...this.groups, ...response.groups];
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        group_id: this.search.group_id || 0,
      };
    },
  },

  asyncComputed: {
    exportWorksheetOptions: {
      async get() {
        const r = await this.$http.get('access-levels/list');
        return r.access_levels;
      },
      default: [],
    }
  },
};
</script>
