<template>
  <div>
    <quick-creator
      v-if="quickCreatorDialog"
      @close="quickCreatorDialog = false"
      @success="fetchCards"
    />

    <title-row title="Kártyák">
      <template #buttons>
        <!-- <title-row-button @click="quickCreatorDialog = true">
          <v-icon left>mdi-credit-card-fast-outline</v-icon>
          Gyors kártyakészítés
        </title-row-button> -->
        <title-row-button :to="{ name: 'NewCard' }">
          <v-icon left>mdi-plus</v-icon>
          Új kártya
        </title-row-button>
      </template>
    </title-row>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col :cols="12" :md="4" :lg="3">
              <v-text-field
                label="Kártya tulajdonos"
                v-model="search.owner_name"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                hide-details
                clearable
              />
            </v-col>
            <v-col :cols="12" :md="4" :lg="2">
              <v-masked-text-field
                label="Kártyaszám"
                v-model="search.card_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                :mask="$config.inputMasks.cardNumber"
                hide-details
                clearable
              />
            </v-col>
            <v-col :cols="12" :md="4" :lg="2">
              <v-masked-text-field
                label="RFID"
                v-model="search.rfid"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                :mask="$config.inputMasks.rfId"
                hide-details
                clearable
                v-uppercase
              />
            </v-col>
            <v-col :cols="12" :md="4" :lg="3">
              <v-masked-text-field
                label="Rendszám"
                v-model="search.plate_number"
                @input="searchEventHandler"
                @click:clear="searchEventHandler"
                :mask="$config.inputMasks.plateNumber"
                hide-details
                clearable
                v-uppercase
              />
            </v-col>
            <v-col :cols="12" :md="4" :lg="2">
              <v-select
                label="Státusz"
                v-model="search.status_id"
                @change="searchEventHandler"
                @click:clear="searchEventHandler"
                :items="cardStatuses"
                item-text="name"
                item-value="id"
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        :item-class="itemClass"
      >
        <template #[`item.photo`]="{ item }">
          <Avatar rounded color="grey" :size="40" :hide-picture="!item.photo" :hash="item.photo" />
        </template>
        <template #[`item.card_number`]="{ item }">
          <div class="font-weight-bold">{{ item.card_number }}</div>
          <div>{{ item.rf_id }}</div>
        </template>
        <template #[`item.status`]="{ item }">
          <v-chip small outlined :color="item.status == 'Aktív' ? 'success' : 'error'">{{ item.status }}</v-chip>
        </template>
        <template #[`item.plate_numbers`]="{ item }">
          <template v-for="number in item.plate_numbers">
            <v-chip :key="`plate-${number}`" v-if="number" small class="mr-1">{{ number }}</v-chip>
          </template>
        </template>
        <template #[`item.actions`]="{ item }">
          <!-- <v-btn
            v-if="hasRight('CARD_WRITE') && hasFeature('CARD_STATUS_CHANGE')"
            small
            fab
            text
            color="primary"
            dark
            elevation="0"
            class="rounded-sm m-2"
            title="Kártya deaktiválása"
          >
            <v-icon>mdi-card-bulleted-off</v-icon>
          </v-btn> -->
          <v-btn
            v-if="hasRight('CARD_WRITE')"
            small
            fab
            text
            color="primary"
            dark
            elevation="0"
            class="rounded-sm m-2"
            :to="{ name: 'CardEditor', params: { id: item.element_id } }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hasRight('CARD_WRITE') && $root.user.filter_element == 1"
            small
            fab
            text
            color="primary"
            dark
            elevation="0"
            class="rounded-sm m-2"
            @click="editButtonInfo"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage, cardReader } from '@/mixins';
import QuickCreator from './QuickCreator.vue';

export default {
  mixins: [dataTablePage, cardReader],

  components: {
    QuickCreator,
  },

  data() {
    return {
      routeName: 'CardList',
      fetchItems: this.fetchCards,
      dataTable: {
        options: {
          sortBy: ['card_number'],
        },
        headers: [
          { text: 'Tulajdonos', value: 'owner_name' },
          { text: 'Kártyaszám / RFID', value: 'card_number' },
          { text: 'Rendszámok', value: 'plate_numbers', sortable: false },
          { text: 'Státusz', value: 'status' },
          { text: '', value: 'actions', sortable: false, align: 'end' },
        ],
      },
      search: {
        card_number: '',
        owner_name: '',
        rfid: '',
        plate_number: '',
        status_id: 1,
      },
      cardStatuses: [],
      quickCreatorDialog: false,
    };
  },

  beforeDestroy() {
    this.closeCardReaderSocket();
  },

  mounted() {
    this.watchCardReader(async (event) => {
      if (event.data) {
        this.clearSearchFields();
        this.search.rfid = event.data;
        this.searchEventHandler();
      }
    });
    this.fetchCardStatuses();
  },

  methods: {
    editButtonInfo() {
      this.$dialog.notify.warn('Ez a művelet nem engedélyezett aktív csoportszűrő esetén.');
      // TODO: implement
    },

    rowClick(value, { item }) {
      this.$router.push({ name: 'CardEditor', params: { id: item.element_id } });
    },

    parseSearchQueries() {
      return {
        card_number: this.$route.query.card_number,
        owner_name: this.$route.query.owner_name,
        rfid: this.$route.query.rfid,
        plate_number: this.$route.query.plate_number,
        status_id: Number(this.$route.query.status_id),
      };
    },

    async fetchCardStatuses() {
      try {
        const response = await this.$http.post('/card-statuses/list');
        this.cardStatuses = response.card_statuses;
      } catch (e) {
        console.error(e);
      }
    },

    clearSearchFields() {
      this.search = {
        card_number: '',
        owner_name: '',
        rfid: '',
        plate_number: '',
        status_id: 0,
      };
    },

    async fetchCards() {
      this.loading = true;
      try {
        const response = await this.$http.post('cards/list', this.options);
        this.dataTable.items = response.cards;
        this.dataTable.itemsLength = response.cards_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        card_number: this.search.card_number || '',
        owner_name: this.search.owner_name || '',
        rfid: this.search.rfid || '',
        plate_number: this.search.plate_number || '',
        status_id: this.search.status_id || '',
      };
    },
  },
};
</script>
