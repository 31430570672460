<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('USER_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewUser' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Adminisztrátorok</h1>

    <list-filter :search="search">
      <v-col :cols="12" :sm="4" :md="3">
        <v-text-field
          v-model="search.keyword"
          @input="searchEventHandler"
          label="Név / E-mail"
          dense
          clearable
          hide-details
          class="my-4 my-sm-0"
        />
      </v-col>
    </list-filter>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        :item-class="itemClass"
      >
        <template #[`item.name`]="{ item }">
          <v-avatar color="grey lighten-4" size="32" class="rounded-sm mr-2">
            <v-icon size="24" color="grey">mdi-account</v-icon>
          </v-avatar>
          {{ item.name }}
          <v-avatar
            v-if="!!Number(item.superadmin)"
            color="primary"
            size="20"
            class="ml-1"
            title="Superadmin"
          >
            <v-icon size="14" color="white"> mdi-star </v-icon>
          </v-avatar>
          <v-avatar
            v-if="!!Number(item.apiuser)"
            color="grey lighten-2"
            size="20"
            class="ml-1"
            title="API felhasználó"
          >
            <v-icon size="14" color="white"> mdi-chip </v-icon>
          </v-avatar>
        </template>

        <template #[`item.actions`]="{ item }">
          <v-btn
            v-if="hasRight('USER_WRITE')"
            :disabled="!!(!Number($store.state.user.superadmin) && Number(item.superadmin))"
            text
            fab
            small
            color="primary"
            class="rounded-sm m-3"
            :to="{ name: 'UserEditor', params: { id: item.element_id } }"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hasRight('USER_WRITE')"
            :disabled="!!(!Number($store.state.user.superadmin) && Number(item.superadmin))"
            text
            fab
            small
            color="primary"
            class="rounded-sm m-3"
            @click="deleteUser(item)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { dataTablePage } from '@/mixins';
export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'UserList',
      fetchItems: this.fetchUsers,
      dataTable: {
        options: {
          sortBy: ['name'],
        },
        headers: [
          { text: 'Név', value: 'name' },
          { text: 'E-mail', value: 'email' },
          { text: '', value: 'actions', align: 'end', sortable: false },
        ],
      },
      search: {
        keyword: '',
      },
    };
  },

  methods: {
    parseSearchQueries() {
      return {
        keyword: this.$route.query.keyword,
      };
    },

    async fetchUsers() {
      this.loading = true;
      try {
        const response = await this.$http.post(
          `users/list`,
          this.postData
        );
        this.dataTable.items = response.users;
        this.dataTable.itemsLength = response.users_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
        return response.users;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },

    async deleteUser(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name} (${item.email})</b> felhasználó törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.get(`users/delete/${item.element_id}`);
        this.fetchUsers();
        if (response.status === 'OK') {
          this.$dialog.notify.info('A felhasználó törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },
  },

  computed: {
    searchQueries() {
      const queries = {
        keyword: this.search.keyword || '',
      };
      for (const field of this.extraFields) {
        if (field.searchable == 1) {
          queries[field.name] = this.search[field.name] || '';
        }
      }
      return queries;
    },

    extraFields() {
      return this.$store.getters['fields/findByModelName']('Users');
    },
  },
};
</script>
